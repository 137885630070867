import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import LogoImages from "../components/logoImages"
import './skills.css'

const Skills = () => {

  const [category, setCategory] = useState('All')

  const defaultButtonColor = '#337ab7'
  const activeButtonColor = '#31b0d5'  

  return (
  <Layout pageTitle="Skills">
    <SEO title="Skills" />
    <h1>Skills</h1>
    <br></br>
    <div id="categories_buttons_container" style={{ textAlign: 'center', marginBottom: '20px', right: 0 }}>
      <button id="All" style={{ backgroundColor: category === 'All' ? activeButtonColor : defaultButtonColor }} onClick={ () => setCategory('All') }>All</button>
      <button id="WebDevFrontend" style={{ backgroundColor: category === 'WebDevFrontend' ? activeButtonColor : defaultButtonColor }} onClick={ () => setCategory('WebDevFrontend') }>Frontend</button>
      <button id="WebDevBackend" style={{ backgroundColor: category === 'WebDevBackend' ? activeButtonColor : defaultButtonColor }} onClick={ () => setCategory('WebDevBackend') }>Backend</button>
      <button id="Data" style={{ backgroundColor: category === 'Data' ? activeButtonColor : defaultButtonColor }} onClick={ () => setCategory('Data') }>Data</button>
      <button id="DevOps" style={{ backgroundColor: category === 'DevOps' ? activeButtonColor : defaultButtonColor }} onClick={ () => setCategory('DevOps') }>DevOps</button>
      <button id="Graphics" style={{ backgroundColor: category === 'Graphics' ? activeButtonColor : defaultButtonColor }} onClick={ () => setCategory('Graphics') }>Graphics</button>
      <button id="Audio" style={{ backgroundColor: category === 'Audio' ? activeButtonColor : defaultButtonColor }} onClick={ () => setCategory('Audio') }>Audio</button>
      <button id="Various" style={{ backgroundColor: category === 'Various' ? activeButtonColor : defaultButtonColor }} onClick={ () => setCategory('Various') }>Various</button>
    </div>  
    <LogoImages category={category}></LogoImages>
  </Layout>
  )  
}

export default Skills
