import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import './logoImage.css'

const LogoImages = (props) => (
  <StaticQuery
    query = {graphql`
    query AssetsPhotos {
      allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {regex: "/logos/"}} sort: { fields: name, order: ASC }) {
        edges {
          node {
            id
            name            
            relativeDirectory                      
            childImageSharp {
              fluid(maxHeight: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    `}
    render={data => {
      const edgesArray = data.allFile.edges.map( (edge, index, array) => {
        const directParentFolder = edge.node.relativeDirectory.lastIndexOf('/')
        edge.node.folder = edge.node.relativeDirectory.substring(directParentFolder + 1)
        return edge
      })

      return (
      <div id="logos_container" style={{ display: 'flex', flexWrap: 'wrap', alignItems:'center', justifyContent: 'center' }}>
        {
          edgesArray.map(edge => {
            return (
              (props.category === 'All' || props.category === edge.node.folder) &&
              <div className={`skillContainer ${edge.node.relativeDirectory}`} style={{ margin: '15px', textAlign: 'center' }} key={edge.node.id}>
                <Img className="logoImage" fluid={edge.node.childImageSharp.fluid} alt={ `${edge.node.name} logo` } title={edge.node.name} />
                {/* <p style={{ marginTop: '5px' }}>{edge.node.name}</p> */}
              </div>
            )
          })
        }
      </div>
      )
    }
    }
    />
)

export default LogoImages